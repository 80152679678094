<template>
  <v-content>
    <div class="page">
      <div class="imgweb d-none d-sm-none d-md-block mt-0 pt-0">
        <AppBar :drawer="false"/>
        <v-container id="topo">
          <a style="text-decoration: none;color: #70445E;" @click.stop="voltar()">VOLTAR</a>
          <v-row class="mt-5">
            <v-col cols="12" class="text-h6" style="color: #70445E;">EN DEHORS – Edição Maio/Junho</v-col>
            <v-col cols="12">
              <v-card
                style="color: #70445E;background-color: transparent;"
              >
              <v-expansion-panels multiple accordion v-model="panel">
                <v-expansion-panel expand style="color: #70445E;background-color: transparent;" v-model="panel">
                  <v-expansion-panel-header class="subtitle-2">EN DEHORS – “Deixa eu me apresentar que eu acabei de chegar”</v-expansion-panel-header>
                  <v-expansion-panel-content class="black--text" style="margin-bottom: 2vh;">
                    <div class="entry-content">
                      <p>&nbsp;</p>
                      <p><iframe loading="lazy" src="//www.youtube.com/embed/GtvS897PiyQ" width="560" height="314" allowfullscreen="allowfullscreen"></iframe></p>
                      <p><span style="font-size: 20px;"><strong>ASSISTA O VÍDEO</strong></span></p>
                      <p>&nbsp;</p>
                      <p>&nbsp;É com essas palavras, que abrem a canção “Amarelo, Azul e Branco” de Anavitória (com participação de Rita Lee), e homenageiam o estado do Tocantins, que eu inicio essa coluna, cuja proposta é falar da arte da dança.</p>
                      <p>&nbsp;Traçando livremente paralelos com a canção, a origem do que sou (inclusive para além da dança) está na bailarina que comecei a aflorar na infância. Comecei com o ballet clássico, aos 8 anos de idade, em uma escola de São Paulo. Ao longo dos anos, fui acumulando graduações da Royal Academy of Dance, método predominante na minha formação, e frequentei, por anos, relevantes festivais nacionais, como o conhecido festival anual de Joinville – SC entre outros.</p>
                      <p>&nbsp;Decidi fazer faculdade de Direito, carreira que admirava e me fez muito feliz por bastante tempo. Essa escolha foi influenciada por um receio, já que me assustava viver de arte em um país como o Brasil, onde não se é dado muito valor. Penso que essa insegurança assola muitos artistas até a atualidade. Escolhi, contudo, manter a dança mais como um hobby do que como uma profissão.</p>
                      <p>&nbsp;Por um capricho gostoso da vida, acabei por ter no ballet clássico meu primeiro emprego. Foi ensinando essa arte para crianças de 2 a 12 anos, que me mantive enquanto cursava a faculdade que, mesmo sendo pública, implicava em gastos com deslocamento e livros.</p>
                      <p>&nbsp;Nesse tempo em que ensinava, também estudei dança do ventre por aproximadamente 3 anos.</p>
                      <p>&nbsp;Atualmente, conciliando dança, trabalho e maternidade, permaneço conectada a essa poesia do corpo fazendo aulas de balllet, jazz e stiletto. Fato é que uma bailarina até pode deixar a dança, sair dela, mas a dança nunca sai da bailarina, nunca a deixa.</p>
                      <p>&nbsp;Como escritora, tenho dois livros lançados em 2021, na Bienal Internacional do Livro do Rio de Janeiro.</p>
                      <p>&nbsp;A poesia de “Preliminares – nudez no verso” tem evidentes influências de minha paixão pela dança. Num dos poemas do livro, o “Dançacasalamento”, cunho esse neologismo por justaposição para sugerir que a relação sexual se dá como que coreografada em movimentos cuja plasticidade cinética é dançante. Há, entre os versos, imagens como “pés arqueados” e “gracejo de flancos”, por exemplo.</p>
                      <p>&nbsp;No meu livro infantil, o conto proposto carrega uma música que pode ser executada por meio de um código QR. A dança também falou em mim nesse trabalho, quando desejei ver essa música viva, sendo “brincada” pelas crianças e não apenas ouvida. Em “Hoje não pode brincar lá fora” trago a possibilidade da imaginação como suporte principal do brincar que encontra limites externos. O contexto foi a pandemia que confinou meus filhos e o quanto os observei. Lembro do quanto dançamos em casa naqueles dias!</p>
                      <p>&nbsp;Portanto, como trouxe na referência musical com que abri esse escrito, também sou “de um lugar onde céu e chão gruda no pé”: o palco. Também não sei – digo à dança – “diferenciar você de mim”. Eu danço para viver e vivo o que tenho dançado (parafraseando a letra original).</p>
                      <p>&nbsp;O nome dessa coluna vem dessa relação de causa e consequência que a mulher que sou enxerga nos anos de trabalho de si própria por meio da dança.</p>
                      <p>&nbsp;O termo en dehors, de origem francesa e muito corrente na linguagem técnica do ballet clássico, significa “para fora”. Ele aduz à rotação das pernas da bailarina, desde a inserção nos quadris, fazendo aquela conhecida figura dos pezinhos como de um pinguim: calcanhares juntos e dedos apontados para fora. É um tanto anti-anatômico -desafiador portanto, e fora da zona de conforto – girar coxas, joelhos e pés que, orientados por essa rotação e a partir desse desenho estético, precisam bailar.</p>
                      <p>&nbsp;Fato é que bailarinas e bailarinos crescem desejando o perfeito en dehors, ouvindo falar dele, buscando o melhor de seu corpo para o alcançar, desafiando-se a si mesmos pelo mais escorreito resultado. Uma torsão de estrutura que expande possibilidades.</p>
                      <p>&nbsp;É, deslizando significados sobre essas duas palavrinhas e ampliando o que cabe nelas, que digo: para fora se lança quem dança. De um grande mergulho interno, de um corpo com pulso e ritmo, aquele que dança se joga para fora de si, para o movimento, para o chão, para o ar, para os giros, para o outro…</p>
                      <p>&nbsp;O corpo em cena desenha mensagens, diz muito sem palavras. O desafio dessa coluna é transpor esse processo às linhas. A partir do corpo em cena, da cena dançante, por para fora, em texto, em ballet de palavras, algo que faça você, meu leitor, sentir também a magia que bailarinos experimentam ao dançar.</p>
                      <p>&nbsp;E já que a arte sabe como ninguém ser multissensorial, condensadora de significados e sensações, deixo como convite para aquecimento: que observem o clipe da música “Amarelo, Azul e Branco”.</p>
                      <p>&nbsp;Primeiramente, porque a melodia e o convívio assíduo com essa musicalidade têm o condão de convocar o corpo acintosamente a mexer-se. Em segundo lugar, porque você que me lê e que está pensando: “isso não é para mim, já que não danço”, poderá notar como é muito mais simples do que parece. Explico: a dupla de cantoras se apresenta com um balé coreografado, desenhando a poesia no ar, com corpos que não se movem exceto por meio das mãos. Noutras palavras, para dançar não e preciso muito, bastam mensagem, entrega e movimento.</p>
                      <p>&nbsp;Que a expressão se faça. Empreste dela o significado. Se joga… en dehors!</p>
                      <p><strong>Por DANIELA LAUBÉ</strong></p>
                   </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel expand style="color: #70445E;background-color: transparent;" v-model="panel">
                  <v-expansion-panel-header class="subtitle-2">EN DEHORS – Pas De Deux</v-expansion-panel-header>
                  <v-expansion-panel-content class="black--text" style="margin-bottom: 2vh;">
                    <div class="entry-content">
                      <p>Nesse cantinho da coluna, convido profissionais ou amantes da dança a trocarem alguns passos comigo. Trazemos à baila projetos e testemunhos sobre essa arte.</p>
                      <p>Para a edição de Maio e Junho, trago uma breve entrevista com uma mulher comum, com dilemas de todas nós, mas com um tempero especial: ela dança!</p>
                      <p>&nbsp;</p>
                      <p style="text-align: center;"><span style="color: #ffcc00; font-size: 24px;"><strong>ENTREVISTA COM TATIANA MENDES</strong></span></p>
                      <blockquote>
                      <p><img loading="lazy" class="alignnone  wp-image-9617" src="https://revistathebard.com/wp-content/uploads/2022/05/TATIANA-MENDES-300x300.png" alt="" width="107" height="107" srcset="https://revistathebard.com/wp-content/uploads/2022/05/TATIANA-MENDES-300x300.png 300w, https://revistathebard.com/wp-content/uploads/2022/05/TATIANA-MENDES-150x150.png 150w, https://revistathebard.com/wp-content/uploads/2022/05/TATIANA-MENDES.png 612w" sizes="(max-width: 107px) 100vw, 107px"><strong>&nbsp;Entre afazeres profissionais, cuidados com a segunda gestação e mimos para com o primogênito, Tatiana Mendes, 34 anos, formada em Cinema/UFMG e empresária no Setor de Publicidade e Produção Audiovisual, conversou comigo:</strong></p>
                      </blockquote>
                      <p style="text-align: center;"><span style="color: #ffcc00;"><strong><span style="font-size: 32px;">1</span></strong></span></p>
                      <p><span style="color: #ffcc00;"><strong>REVISTA THE BARD</strong></span>&nbsp;Quando você começou a dançar?</p>
                      <p><strong><span style="color: #993300;">TATIANA MENDES:</span></strong> Eu sempre gostei de dançar. Fiz balé na infância, mas não dei continuidade no estudo. Na escola, sempre participei de todos os festivais de dança e fazia apresentações até o fim do ensino médio.</p>
                      <p>Na faculdade, iniciei uma aula de jazz, mas não consegui continuar. Depois de formada, fiz 3 meses de Stiletto e também tive que parar. Logo após, conheci um grupo de danças urbanas e comecei a dançar com eles. Dancei por 2 anos. Nunca consegui estudar como gostaria. Somente depois de 31 anos e de ser mãe que consegui voltar e dar continuidade em aulas de jazz funk e stiletto que faço até hoje.</p>
                      <p>&nbsp;</p>
                      <p style="text-align: center;"><span style="font-size: 32px; color: #ffcc00;"><strong>2</strong></span></p>
                      <p><span style="color: #ffcc00;"><strong>REVISTA THE BARD</strong> </span>Qual o benefício da dança na sua vida?</p>
                      <p><span style="color: #993300;"><strong>TATIANA MENDES:</strong></span> Só hoje eu tenho plena consciência desse benefício. Assim como o mundo encara, eu também encarava como hobby e nunca levei tão a sério. Sempre ficou em segundo plano para mim. Hoje, eu tenho consciência do bem que a dança me proporciona. Depois que tive meu primeiro filho, passei por uma série de problemas físicos e emocionais que desencadearam uma depressão profunda com sequelas físicas e psicológicas. Depois de um ano, quando estava um pouco melhor, voltei a dançar e isso me trouxe de volta. De volta à autoestima, o prazer de fazer algo que eu amo, alegria, ânimo e tudo que me faltava. Foi quando percebi que o meu remédio controlado era a dança e ela, inclusive, me ajudou a suspender a medicação clínica. Hoje, não fico sem dançar, paro tudo e vou, brigo e luto por esse direito pois sei o bem que me faz. Hoje, estou grávida novamente e continuo dançando enquanto tiver disposição.</p>
                      <p>&nbsp;</p>
                      <p style="text-align: center;"><span style="color: #ffcc00;"><strong><span style="font-size: 32px;">3</span></strong></span></p>
                      <p><span style="color: #ffcc00;"><strong>REVISTA THE BARD</strong></span> Como foi dançar e ser mãe ao mesmo tempo? Mudou alguma coisa?</p>
                      <p><strong><span style="color: #993300;">TATIANA MENDES:</span></strong> Mudou tudo. Ser mãe rotula automaticamente as mulheres e isso está “preso” em nós, mulheres, inconscientemente. Depois que o bebê nasce, o corpo muda, não conseguimos nos cuidar, ficamos exaustas e não temos tempo para nós mesmas. A autoestima desce pelo ralo, esquecemos que somos mulheres também e não deveria ser assim. A dança resgatou a mulher que eu era anos atrás e me lembrou como eu era feliz, de bem comigo mesma, confiante e destemida. Ela trouxe de volta uma pessoa que eu era e tinha esquecido, bem antes de ter filho. Se eu pudesse, faria isso por todas as mulheres.</p>
                      <p>&nbsp;</p>
                      <p style="text-align: center;"><span style="color: #ffcc00;"><strong><span style="font-size: 32px;">4</span></strong></span></p>
                      <p><strong><span style="color: #ffcc00;">REVISTA THE BARD</span></strong> Que mensagem você deixaria para as mulheres, em especial que são ou pretendem ser mães, e que desejam dançar, mas não tem coragem?</p>
                      <p><strong><span style="color: #993300;">TATIANA MENDES:</span></strong> Como eu posto minhas danças no instagram, eu recebo, às vezes, mensagens de mulheres da minha idade, mais novas e mais velhas, perguntando onde eu danço e dizendo que amavam dançar ou que gostariam, mas não dançam. Se as mulheres que gostam de dançar soubessem o bem que a dança pode fazer, em todos os sentidos, eu tenho certeza que todas fariam. O problema não é só o preconceito dos outros, mas o nosso também. Achamos que dança é coisa de adolescente e não é apropriado para mães e mulheres casadas ou mais velhas. E já cansei de ouvir: “você é mãe”… “seu marido deixa?”… e coisas do tipo. A questão é você fazer por você, porque se você não fizer, ninguém mais faz. Nem homem e nem pai nenhum é rotulado por jogar bola, pescar ou fazer qualquer coisa, mas nós somos e depende de nós mudarmos isso.</p>
                      <p><strong>Por DANIELA LAUBÉ</strong></p>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel expand style="color: #70445E;background-color: transparent;" v-model="panel">
                  <v-expansion-panel-header class="subtitle-2">EN DEHORS – Uma breve história de dança</v-expansion-panel-header>
                  <v-expansion-panel-content class="black--text" style="margin-bottom: 2vh;">
                    <div class="entry-content">
                      <p>&nbsp;Em um dia desses, estive na casa da minha mãe e resolvi pegar minha coleção de sapatilhas. Sei que parece estranho, mas eu tinha guardadas as sapatilhas que usei ao longo da vida, sujas, gastas, manchadas dos machucados feitos nos pés. Foi o mesmo que iniciar uma viagem no tempo…</p>
                      <p>&nbsp;Minha primeira sapatilha, aos meus oito anos de idade, saltou com meus pés pequeninos ao som de Tritsch-Tratsch-Polka de Johann Strauss, no antigo palco do Teatro Cultura Artística, inaugurado em 8 de março de 1950 com regência da Orquestra Sinfônica do Estado de São Paulo por Heitor Villa-Lobos e Camargo Guarnieri, no terreno do antigo Velódromo de São Paulo, primeiro estádio de futebol do país.</p>
                      <p>&nbsp;Sua fachada conta com o maior afresco existente do artista Di Cavalcanti (são 48 metros de largura por 8 metros de altura em um lindo mosaico).</p>
                      <p>&nbsp;Em agosto de 2008, o teatro foi parcialmente destruído por um incêndio e eu me lembro da tristeza com que vi as labaredas na televisão, já que nos 18 anos de dança que eu tinha à época, havia desfrutado de seus interiores muitas outras vezes. Tombado, o teatro atualmente está em obras de resgate da arquitetura original, com previsão de reabertura ainda em 2022.</p>
                      <p>&nbsp;É a partir desse ponto, situado no tempo e no espaço, que proponho uma reflexão sobre quantas artes invadem nosso cotidiano a partir de uma que convidemos a entrar.</p>
                      <p>&nbsp;Pelo ballet clássico, minha infância guarda recordações coloridas do mosaico de Di Cavalcanti; meus pés tomaram impulso para giros e saltos no palco que recebeu Villa-Lobos e Guarnieri; eu brinquei de esconder em uma obra arquitetônica tombada e que passa a ser, de certa forma, parte do meu patrimônio histórico-cultural, aquele que ostento entremeado às mais doces lembranças.</p>
                      <p>&nbsp;Estive noutros teatros, palcos, cidades… mas fico com esse! Por ser marco de um princípio. E digo isso porque há mais ecos que carregam minhas sapatilhas velhas.</p>
                      <p>&nbsp;Sobre elas, dancei. Quando danço carrego uma tal humanidade no corpo que é constituinte do próprio ser humano que conhecemos atualmente.</p>
                      <p>&nbsp;Voltemos a falar de origem. Estudos apontam que os primeiros registros da dança como forma de expressão são datados dos remotos idos dos anos 25.000 a 10.000 a.C. aproximadamente. O movimento dançado – observado a partir da natureza – é referencial para o início da comunicação do homem.</p>
                      <p>&nbsp;Imagine o homem primitivo, a falta de uma linguagem estruturada como a que temos hoje, mas a comunicação se valendo da via da dança…</p>
                      <p>&nbsp;Chamo a atenção a isso: quando um corpo dança, carrega todo esse arcabouço constituinte de si mesmo. Há poesia silenciosa de palavras, eloquente de movimentos, origem latente, ecos de passado, expectativa de futuro. Arte!</p>
                      <p>&nbsp;Talvez por isso Paul Auster, escritor norte-americano nascido em 1947, tenha encerrado sua produção poética, seguindo apenas com a prosa, após o êxtase libertador que experimentou ao assistir a um espetáculo de dança. Voltaremos a esse episódio, futuramente, ainda nessa coluna.</p>
                      <p><img loading="lazy" class="alignnone size-medium wp-image-9596" src="https://revistathebard.com/wp-content/uploads/2022/05/DANIELA-LAUBE-300x282.jpg" alt="" width="300" height="282" srcset="https://revistathebard.com/wp-content/uploads/2022/05/DANIELA-LAUBE-300x282.jpg 300w, https://revistathebard.com/wp-content/uploads/2022/05/DANIELA-LAUBE-1024x964.jpg 1024w, https://revistathebard.com/wp-content/uploads/2022/05/DANIELA-LAUBE-768x723.jpg 768w, https://revistathebard.com/wp-content/uploads/2022/05/DANIELA-LAUBE-1170x1101.jpg 1170w, https://revistathebard.com/wp-content/uploads/2022/05/DANIELA-LAUBE.jpg 1242w" sizes="(max-width: 300px) 100vw, 300px">&nbsp; &nbsp; &nbsp;</p>
                      <p><img loading="lazy" class="alignnone  wp-image-9597" src="https://revistathebard.com/wp-content/uploads/2022/05/IGTV_logo.png" alt="" width="79" height="79" srcset="https://revistathebard.com/wp-content/uploads/2022/05/IGTV_logo.png 246w, https://revistathebard.com/wp-content/uploads/2022/05/IGTV_logo-150x150.png 150w" sizes="(max-width: 79px) 100vw, 79px"></p>
                      <p><a href="https://www.instagram.com/tv/Cbx6tY1AZQ7/?igshid=YmMyMTA2M2Y=" role="link">Clique para assistir&nbsp;&nbsp;</a></p>
                      <p>&nbsp;</p>
                      <p>&nbsp;</p>
                      <p><strong>Por DANIELA LAUBÉ</strong></p>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel expand style="color: #70445E;background-color: transparent;" v-model="panel">
                  <v-expansion-panel-header class="subtitle-2">EN DEHORS – Um “anarriê” nas festividades juninas</v-expansion-panel-header>
                  <v-expansion-panel-content class="black--text" style="margin-bottom: 2vh;">
                    <div class="entry-content">
                      <p>&nbsp;Marcando ainda o mesmo passo, digo e repito: a dança está em tudo, mais presente do que imaginamos! Caro leitor, se você já foi estudante um dia, já se trajou a caráter para uma festa junina, dançou quadrilha e ouviu um “anarriê”.</p>
                      <p>&nbsp;É sob o comando do “anarriê” que os pares, cavalheiros e moças, retomam suas posições na quadrilha – após um túnel, galope, caracol, etc. A palavra tem nascente na língua francesa e, nesse contexto, não é à toa.</p>
                      <p>&nbsp;A terminologia do ballet clássico é universal e tem a maioria dos termos em língua francesa, parte também em inglês, e surgiu de grandes mestres da dança, atravessando gerações. Muitas vezes, a tradução é apenas aproximada por não ser possível importar o correlato literal.</p>
                      <p>&nbsp;Pois bem, en arrière (cuja pronúncia poderíamos, grosso modo, determinar assim: “ãn-arriér”) significa “para trás”. O termo é utilizado para indicar a direção de um passo qualquer, envolvendo recuo, deslocamento para trás, orientado em direção oposta ao público.</p>
                      <p>&nbsp;Ora, vejam! Do ballet para a quadrilha; dos bailarinos e bailarinas para a dupla caipira; abrasileiramos o termo com um saboroso e sonoro anarriê.</p>
                      <p>&nbsp;Do que trata isso? Estamos a falar de tradição e cultura. Intercambialidade da arte que ganha o mundo se horizontalizando no espaço e no tempo.</p>
                      <p>&nbsp;As festas juninas tiveram origem pagã relacionada à colheita, antecedendo a chegada do verão no hemisfério norte. Depois, foi acrescida do significado religioso, associada às festividades dos dias de Santo Antônio, São Pedro e São João (respectivamente 12, 23 e 24 de junho).</p>
                      <p>&nbsp;Fogueira, balões, comidas típicas e, sempre, dança! No Brasil, as escolas ensinam por meio da tradição que perpetuam.</p>
                      <p>&nbsp;Indago, portanto – já que vínhamos falando de origem, história, sapatilhas velhas e paleolítico – qual é a porta pela qual as crianças, trajadas de caipiras, se apropriam dessa cultura? É, exatamente, por meio das danças típicas.</p>
                      <p>&nbsp;Não é cozinhando canjica, nem pondo fogo em balões, menos ainda bebendo quentão.</p>
                      <p>&nbsp;Dançando essa tradição, crianças tomam contato com a cordialidade entre damas e cavalheiros, com a cumplicidade, e até com a narrativa do casamento. Pela via do humor, começam a pensar na moça que é prometida e no noivo fujão (que relação é essa?). Críticas deixadas de lado e dialética do certo e errado adiada para mais tarde: está aberto o debate!</p>
                      <p>&nbsp;Disso tudo, o que acho mais belo (núcleo da arte) e expressivo (necessidade do humano) é o que concluo a seguir: a dança é inata. O coração é rítmico. A respiração é ininterruptamente pulsante. O movimento é vida e por ele se constrói linguagem.</p>
                      <p>&nbsp;Dançar transforma, entretém e comunica. Salva!</p>
                      <p><strong>Por DANIELA LAUBÉ</strong></p>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              </v-card>
              <div style="margin-bottom: 25vh;">
                <span><a class="linkGaleria" @click="goTop()">Topo</a></span>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div class="imgmobi d-md-none d-lg-none d-xl-none pt-0 mt-0" style="overflow-y: auto !important;" id="topo">
        <AppBar :drawer="false"/>
        <div class="container">
          <div>
            <a style="text-decoration: none;color: #70445E;" @click.stop="voltar()">VOLTAR</a>
          </div>
          <span cols="12" class="text-h6" style="color: #70445E;">EN DEHORS – Edição Maio/Junho</span>
          <v-card
            style="color: #70445E;background-color: transparent;"
          >
            <v-expansion-panels multiple accordion v-model="panel">
                <v-expansion-panel expand style="color: #70445E;background-color: transparent;" v-model="panel">
                  <v-expansion-panel-header class="subtitle-2">EN DEHORS – “Deixa eu me apresentar que eu acabei de chegar”</v-expansion-panel-header>
                  <v-expansion-panel-content class="black--text" style="margin-bottom: 2vh;">
                    <div class="entry-content">
                      <p>&nbsp;</p>
                      <p><iframe loading="lazy" src="//www.youtube.com/embed/GtvS897PiyQ" allowfullscreen="allowfullscreen"></iframe></p>
                      <p><span style="font-size: 20px;"><strong>ASSISTA O VÍDEO</strong></span></p>
                      <p>&nbsp;</p>
                      <p>&nbsp;É com essas palavras, que abrem a canção “Amarelo, Azul e Branco” de Anavitória (com participação de Rita Lee), e homenageiam o estado do Tocantins, que eu inicio essa coluna, cuja proposta é falar da arte da dança.</p>
                      <p>&nbsp;Traçando livremente paralelos com a canção, a origem do que sou (inclusive para além da dança) está na bailarina que comecei a aflorar na infância. Comecei com o ballet clássico, aos 8 anos de idade, em uma escola de São Paulo. Ao longo dos anos, fui acumulando graduações da Royal Academy of Dance, método predominante na minha formação, e frequentei, por anos, relevantes festivais nacionais, como o conhecido festival anual de Joinville – SC entre outros.</p>
                      <p>&nbsp;Decidi fazer faculdade de Direito, carreira que admirava e me fez muito feliz por bastante tempo. Essa escolha foi influenciada por um receio, já que me assustava viver de arte em um país como o Brasil, onde não se é dado muito valor. Penso que essa insegurança assola muitos artistas até a atualidade. Escolhi, contudo, manter a dança mais como um hobby do que como uma profissão.</p>
                      <p>&nbsp;Por um capricho gostoso da vida, acabei por ter no ballet clássico meu primeiro emprego. Foi ensinando essa arte para crianças de 2 a 12 anos, que me mantive enquanto cursava a faculdade que, mesmo sendo pública, implicava em gastos com deslocamento e livros.</p>
                      <p>&nbsp;Nesse tempo em que ensinava, também estudei dança do ventre por aproximadamente 3 anos.</p>
                      <p>&nbsp;Atualmente, conciliando dança, trabalho e maternidade, permaneço conectada a essa poesia do corpo fazendo aulas de balllet, jazz e stiletto. Fato é que uma bailarina até pode deixar a dança, sair dela, mas a dança nunca sai da bailarina, nunca a deixa.</p>
                      <p>&nbsp;Como escritora, tenho dois livros lançados em 2021, na Bienal Internacional do Livro do Rio de Janeiro.</p>
                      <p>&nbsp;A poesia de “Preliminares – nudez no verso” tem evidentes influências de minha paixão pela dança. Num dos poemas do livro, o “Dançacasalamento”, cunho esse neologismo por justaposição para sugerir que a relação sexual se dá como que coreografada em movimentos cuja plasticidade cinética é dançante. Há, entre os versos, imagens como “pés arqueados” e “gracejo de flancos”, por exemplo.</p>
                      <p>&nbsp;No meu livro infantil, o conto proposto carrega uma música que pode ser executada por meio de um código QR. A dança também falou em mim nesse trabalho, quando desejei ver essa música viva, sendo “brincada” pelas crianças e não apenas ouvida. Em “Hoje não pode brincar lá fora” trago a possibilidade da imaginação como suporte principal do brincar que encontra limites externos. O contexto foi a pandemia que confinou meus filhos e o quanto os observei. Lembro do quanto dançamos em casa naqueles dias!</p>
                      <p>&nbsp;Portanto, como trouxe na referência musical com que abri esse escrito, também sou “de um lugar onde céu e chão gruda no pé”: o palco. Também não sei – digo à dança – “diferenciar você de mim”. Eu danço para viver e vivo o que tenho dançado (parafraseando a letra original).</p>
                      <p>&nbsp;O nome dessa coluna vem dessa relação de causa e consequência que a mulher que sou enxerga nos anos de trabalho de si própria por meio da dança.</p>
                      <p>&nbsp;O termo en dehors, de origem francesa e muito corrente na linguagem técnica do ballet clássico, significa “para fora”. Ele aduz à rotação das pernas da bailarina, desde a inserção nos quadris, fazendo aquela conhecida figura dos pezinhos como de um pinguim: calcanhares juntos e dedos apontados para fora. É um tanto anti-anatômico -desafiador portanto, e fora da zona de conforto – girar coxas, joelhos e pés que, orientados por essa rotação e a partir desse desenho estético, precisam bailar.</p>
                      <p>&nbsp;Fato é que bailarinas e bailarinos crescem desejando o perfeito en dehors, ouvindo falar dele, buscando o melhor de seu corpo para o alcançar, desafiando-se a si mesmos pelo mais escorreito resultado. Uma torsão de estrutura que expande possibilidades.</p>
                      <p>&nbsp;É, deslizando significados sobre essas duas palavrinhas e ampliando o que cabe nelas, que digo: para fora se lança quem dança. De um grande mergulho interno, de um corpo com pulso e ritmo, aquele que dança se joga para fora de si, para o movimento, para o chão, para o ar, para os giros, para o outro…</p>
                      <p>&nbsp;O corpo em cena desenha mensagens, diz muito sem palavras. O desafio dessa coluna é transpor esse processo às linhas. A partir do corpo em cena, da cena dançante, por para fora, em texto, em ballet de palavras, algo que faça você, meu leitor, sentir também a magia que bailarinos experimentam ao dançar.</p>
                      <p>&nbsp;E já que a arte sabe como ninguém ser multissensorial, condensadora de significados e sensações, deixo como convite para aquecimento: que observem o clipe da música “Amarelo, Azul e Branco”.</p>
                      <p>&nbsp;Primeiramente, porque a melodia e o convívio assíduo com essa musicalidade têm o condão de convocar o corpo acintosamente a mexer-se. Em segundo lugar, porque você que me lê e que está pensando: “isso não é para mim, já que não danço”, poderá notar como é muito mais simples do que parece. Explico: a dupla de cantoras se apresenta com um balé coreografado, desenhando a poesia no ar, com corpos que não se movem exceto por meio das mãos. Noutras palavras, para dançar não e preciso muito, bastam mensagem, entrega e movimento.</p>
                      <p>&nbsp;Que a expressão se faça. Empreste dela o significado. Se joga… en dehors!</p>
                      <p><strong>Por DANIELA LAUBÉ</strong></p>
                   </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel expand style="color: #70445E;background-color: transparent;" v-model="panel">
                  <v-expansion-panel-header class="subtitle-2">EN DEHORS – Pas De Deux</v-expansion-panel-header>
                  <v-expansion-panel-content class="black--text" style="margin-bottom: 2vh;">
                    <div class="entry-content">
                      <p>Nesse cantinho da coluna, convido profissionais ou amantes da dança a trocarem alguns passos comigo. Trazemos à baila projetos e testemunhos sobre essa arte.</p>
                      <p>Para a edição de Maio e Junho, trago uma breve entrevista com uma mulher comum, com dilemas de todas nós, mas com um tempero especial: ela dança!</p>
                      <p>&nbsp;</p>
                      <p style="text-align: center;"><span style="color: #ffcc00; font-size: 24px;"><strong>ENTREVISTA COM TATIANA MENDES</strong></span></p>
                      <blockquote>
                      <p><img loading="lazy" class="alignnone  wp-image-9617" src="https://revistathebard.com/wp-content/uploads/2022/05/TATIANA-MENDES-300x300.png" alt="" width="107" height="107" srcset="https://revistathebard.com/wp-content/uploads/2022/05/TATIANA-MENDES-300x300.png 300w, https://revistathebard.com/wp-content/uploads/2022/05/TATIANA-MENDES-150x150.png 150w, https://revistathebard.com/wp-content/uploads/2022/05/TATIANA-MENDES.png 612w" sizes="(max-width: 107px) 100vw, 107px"><strong>&nbsp;Entre afazeres profissionais, cuidados com a segunda gestação e mimos para com o primogênito, Tatiana Mendes, 34 anos, formada em Cinema/UFMG e empresária no Setor de Publicidade e Produção Audiovisual, conversou comigo:</strong></p>
                      </blockquote>
                      <p style="text-align: center;"><span style="color: #ffcc00;"><strong><span style="font-size: 32px;">1</span></strong></span></p>
                      <p><span style="color: #ffcc00;"><strong>REVISTA THE BARD</strong></span>&nbsp;Quando você começou a dançar?</p>
                      <p><strong><span style="color: #993300;">TATIANA MENDES:</span></strong> Eu sempre gostei de dançar. Fiz balé na infância, mas não dei continuidade no estudo. Na escola, sempre participei de todos os festivais de dança e fazia apresentações até o fim do ensino médio.</p>
                      <p>Na faculdade, iniciei uma aula de jazz, mas não consegui continuar. Depois de formada, fiz 3 meses de Stiletto e também tive que parar. Logo após, conheci um grupo de danças urbanas e comecei a dançar com eles. Dancei por 2 anos. Nunca consegui estudar como gostaria. Somente depois de 31 anos e de ser mãe que consegui voltar e dar continuidade em aulas de jazz funk e stiletto que faço até hoje.</p>
                      <p>&nbsp;</p>
                      <p style="text-align: center;"><span style="font-size: 32px; color: #ffcc00;"><strong>2</strong></span></p>
                      <p><span style="color: #ffcc00;"><strong>REVISTA THE BARD</strong> </span>Qual o benefício da dança na sua vida?</p>
                      <p><span style="color: #993300;"><strong>TATIANA MENDES:</strong></span> Só hoje eu tenho plena consciência desse benefício. Assim como o mundo encara, eu também encarava como hobby e nunca levei tão a sério. Sempre ficou em segundo plano para mim. Hoje, eu tenho consciência do bem que a dança me proporciona. Depois que tive meu primeiro filho, passei por uma série de problemas físicos e emocionais que desencadearam uma depressão profunda com sequelas físicas e psicológicas. Depois de um ano, quando estava um pouco melhor, voltei a dançar e isso me trouxe de volta. De volta à autoestima, o prazer de fazer algo que eu amo, alegria, ânimo e tudo que me faltava. Foi quando percebi que o meu remédio controlado era a dança e ela, inclusive, me ajudou a suspender a medicação clínica. Hoje, não fico sem dançar, paro tudo e vou, brigo e luto por esse direito pois sei o bem que me faz. Hoje, estou grávida novamente e continuo dançando enquanto tiver disposição.</p>
                      <p>&nbsp;</p>
                      <p style="text-align: center;"><span style="color: #ffcc00;"><strong><span style="font-size: 32px;">3</span></strong></span></p>
                      <p><span style="color: #ffcc00;"><strong>REVISTA THE BARD</strong></span> Como foi dançar e ser mãe ao mesmo tempo? Mudou alguma coisa?</p>
                      <p><strong><span style="color: #993300;">TATIANA MENDES:</span></strong> Mudou tudo. Ser mãe rotula automaticamente as mulheres e isso está “preso” em nós, mulheres, inconscientemente. Depois que o bebê nasce, o corpo muda, não conseguimos nos cuidar, ficamos exaustas e não temos tempo para nós mesmas. A autoestima desce pelo ralo, esquecemos que somos mulheres também e não deveria ser assim. A dança resgatou a mulher que eu era anos atrás e me lembrou como eu era feliz, de bem comigo mesma, confiante e destemida. Ela trouxe de volta uma pessoa que eu era e tinha esquecido, bem antes de ter filho. Se eu pudesse, faria isso por todas as mulheres.</p>
                      <p>&nbsp;</p>
                      <p style="text-align: center;"><span style="color: #ffcc00;"><strong><span style="font-size: 32px;">4</span></strong></span></p>
                      <p><strong><span style="color: #ffcc00;">REVISTA THE BARD</span></strong> Que mensagem você deixaria para as mulheres, em especial que são ou pretendem ser mães, e que desejam dançar, mas não tem coragem?</p>
                      <p><strong><span style="color: #993300;">TATIANA MENDES:</span></strong> Como eu posto minhas danças no instagram, eu recebo, às vezes, mensagens de mulheres da minha idade, mais novas e mais velhas, perguntando onde eu danço e dizendo que amavam dançar ou que gostariam, mas não dançam. Se as mulheres que gostam de dançar soubessem o bem que a dança pode fazer, em todos os sentidos, eu tenho certeza que todas fariam. O problema não é só o preconceito dos outros, mas o nosso também. Achamos que dança é coisa de adolescente e não é apropriado para mães e mulheres casadas ou mais velhas. E já cansei de ouvir: “você é mãe”… “seu marido deixa?”… e coisas do tipo. A questão é você fazer por você, porque se você não fizer, ninguém mais faz. Nem homem e nem pai nenhum é rotulado por jogar bola, pescar ou fazer qualquer coisa, mas nós somos e depende de nós mudarmos isso.</p>
                      <p><strong>Por DANIELA LAUBÉ</strong></p>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel expand style="color: #70445E;background-color: transparent;" v-model="panel">
                  <v-expansion-panel-header class="subtitle-2">EN DEHORS – Uma breve história de dança</v-expansion-panel-header>
                  <v-expansion-panel-content class="black--text" style="margin-bottom: 2vh;">
                    <div class="entry-content">
                      <p>&nbsp;Em um dia desses, estive na casa da minha mãe e resolvi pegar minha coleção de sapatilhas. Sei que parece estranho, mas eu tinha guardadas as sapatilhas que usei ao longo da vida, sujas, gastas, manchadas dos machucados feitos nos pés. Foi o mesmo que iniciar uma viagem no tempo…</p>
                      <p>&nbsp;Minha primeira sapatilha, aos meus oito anos de idade, saltou com meus pés pequeninos ao som de Tritsch-Tratsch-Polka de Johann Strauss, no antigo palco do Teatro Cultura Artística, inaugurado em 8 de março de 1950 com regência da Orquestra Sinfônica do Estado de São Paulo por Heitor Villa-Lobos e Camargo Guarnieri, no terreno do antigo Velódromo de São Paulo, primeiro estádio de futebol do país.</p>
                      <p>&nbsp;Sua fachada conta com o maior afresco existente do artista Di Cavalcanti (são 48 metros de largura por 8 metros de altura em um lindo mosaico).</p>
                      <p>&nbsp;Em agosto de 2008, o teatro foi parcialmente destruído por um incêndio e eu me lembro da tristeza com que vi as labaredas na televisão, já que nos 18 anos de dança que eu tinha à época, havia desfrutado de seus interiores muitas outras vezes. Tombado, o teatro atualmente está em obras de resgate da arquitetura original, com previsão de reabertura ainda em 2022.</p>
                      <p>&nbsp;É a partir desse ponto, situado no tempo e no espaço, que proponho uma reflexão sobre quantas artes invadem nosso cotidiano a partir de uma que convidemos a entrar.</p>
                      <p>&nbsp;Pelo ballet clássico, minha infância guarda recordações coloridas do mosaico de Di Cavalcanti; meus pés tomaram impulso para giros e saltos no palco que recebeu Villa-Lobos e Guarnieri; eu brinquei de esconder em uma obra arquitetônica tombada e que passa a ser, de certa forma, parte do meu patrimônio histórico-cultural, aquele que ostento entremeado às mais doces lembranças.</p>
                      <p>&nbsp;Estive noutros teatros, palcos, cidades… mas fico com esse! Por ser marco de um princípio. E digo isso porque há mais ecos que carregam minhas sapatilhas velhas.</p>
                      <p>&nbsp;Sobre elas, dancei. Quando danço carrego uma tal humanidade no corpo que é constituinte do próprio ser humano que conhecemos atualmente.</p>
                      <p>&nbsp;Voltemos a falar de origem. Estudos apontam que os primeiros registros da dança como forma de expressão são datados dos remotos idos dos anos 25.000 a 10.000 a.C. aproximadamente. O movimento dançado – observado a partir da natureza – é referencial para o início da comunicação do homem.</p>
                      <p>&nbsp;Imagine o homem primitivo, a falta de uma linguagem estruturada como a que temos hoje, mas a comunicação se valendo da via da dança…</p>
                      <p>&nbsp;Chamo a atenção a isso: quando um corpo dança, carrega todo esse arcabouço constituinte de si mesmo. Há poesia silenciosa de palavras, eloquente de movimentos, origem latente, ecos de passado, expectativa de futuro. Arte!</p>
                      <p>&nbsp;Talvez por isso Paul Auster, escritor norte-americano nascido em 1947, tenha encerrado sua produção poética, seguindo apenas com a prosa, após o êxtase libertador que experimentou ao assistir a um espetáculo de dança. Voltaremos a esse episódio, futuramente, ainda nessa coluna.</p>
                      <p><img loading="lazy" class="alignnone size-medium wp-image-9596" src="https://revistathebard.com/wp-content/uploads/2022/05/DANIELA-LAUBE-300x282.jpg" alt="" width="300" height="282" srcset="https://revistathebard.com/wp-content/uploads/2022/05/DANIELA-LAUBE-300x282.jpg 300w, https://revistathebard.com/wp-content/uploads/2022/05/DANIELA-LAUBE-1024x964.jpg 1024w, https://revistathebard.com/wp-content/uploads/2022/05/DANIELA-LAUBE-768x723.jpg 768w, https://revistathebard.com/wp-content/uploads/2022/05/DANIELA-LAUBE-1170x1101.jpg 1170w, https://revistathebard.com/wp-content/uploads/2022/05/DANIELA-LAUBE.jpg 1242w" sizes="(max-width: 300px) 100vw, 300px">&nbsp; &nbsp; &nbsp;</p>
                      <p><img loading="lazy" class="alignnone  wp-image-9597" src="https://revistathebard.com/wp-content/uploads/2022/05/IGTV_logo.png" alt="" width="79" height="79" srcset="https://revistathebard.com/wp-content/uploads/2022/05/IGTV_logo.png 246w, https://revistathebard.com/wp-content/uploads/2022/05/IGTV_logo-150x150.png 150w" sizes="(max-width: 79px) 100vw, 79px"></p>
                      <p><a href="https://www.instagram.com/tv/Cbx6tY1AZQ7/?igshid=YmMyMTA2M2Y=" role="link">Clique para assistir&nbsp;&nbsp;</a></p>
                      <p>&nbsp;</p>
                      <p>&nbsp;</p>
                      <p><strong>Por DANIELA LAUBÉ</strong></p>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel expand style="color: #70445E;background-color: transparent;" v-model="panel">
                  <v-expansion-panel-header class="subtitle-2">EN DEHORS – Um “anarriê” nas festividades juninas</v-expansion-panel-header>
                  <v-expansion-panel-content class="black--text" style="margin-bottom: 2vh;">
                    <div class="entry-content">
                      <p>&nbsp;Marcando ainda o mesmo passo, digo e repito: a dança está em tudo, mais presente do que imaginamos! Caro leitor, se você já foi estudante um dia, já se trajou a caráter para uma festa junina, dançou quadrilha e ouviu um “anarriê”.</p>
                      <p>&nbsp;É sob o comando do “anarriê” que os pares, cavalheiros e moças, retomam suas posições na quadrilha – após um túnel, galope, caracol, etc. A palavra tem nascente na língua francesa e, nesse contexto, não é à toa.</p>
                      <p>&nbsp;A terminologia do ballet clássico é universal e tem a maioria dos termos em língua francesa, parte também em inglês, e surgiu de grandes mestres da dança, atravessando gerações. Muitas vezes, a tradução é apenas aproximada por não ser possível importar o correlato literal.</p>
                      <p>&nbsp;Pois bem, en arrière (cuja pronúncia poderíamos, grosso modo, determinar assim: “ãn-arriér”) significa “para trás”. O termo é utilizado para indicar a direção de um passo qualquer, envolvendo recuo, deslocamento para trás, orientado em direção oposta ao público.</p>
                      <p>&nbsp;Ora, vejam! Do ballet para a quadrilha; dos bailarinos e bailarinas para a dupla caipira; abrasileiramos o termo com um saboroso e sonoro anarriê.</p>
                      <p>&nbsp;Do que trata isso? Estamos a falar de tradição e cultura. Intercambialidade da arte que ganha o mundo se horizontalizando no espaço e no tempo.</p>
                      <p>&nbsp;As festas juninas tiveram origem pagã relacionada à colheita, antecedendo a chegada do verão no hemisfério norte. Depois, foi acrescida do significado religioso, associada às festividades dos dias de Santo Antônio, São Pedro e São João (respectivamente 12, 23 e 24 de junho).</p>
                      <p>&nbsp;Fogueira, balões, comidas típicas e, sempre, dança! No Brasil, as escolas ensinam por meio da tradição que perpetuam.</p>
                      <p>&nbsp;Indago, portanto – já que vínhamos falando de origem, história, sapatilhas velhas e paleolítico – qual é a porta pela qual as crianças, trajadas de caipiras, se apropriam dessa cultura? É, exatamente, por meio das danças típicas.</p>
                      <p>&nbsp;Não é cozinhando canjica, nem pondo fogo em balões, menos ainda bebendo quentão.</p>
                      <p>&nbsp;Dançando essa tradição, crianças tomam contato com a cordialidade entre damas e cavalheiros, com a cumplicidade, e até com a narrativa do casamento. Pela via do humor, começam a pensar na moça que é prometida e no noivo fujão (que relação é essa?). Críticas deixadas de lado e dialética do certo e errado adiada para mais tarde: está aberto o debate!</p>
                      <p>&nbsp;Disso tudo, o que acho mais belo (núcleo da arte) e expressivo (necessidade do humano) é o que concluo a seguir: a dança é inata. O coração é rítmico. A respiração é ininterruptamente pulsante. O movimento é vida e por ele se constrói linguagem.</p>
                      <p>&nbsp;Dançar transforma, entretém e comunica. Salva!</p>
                      <p><strong>Por DANIELA LAUBÉ</strong></p>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
          </v-card>
          <div style="margin-bottom: 25vh;">
            <span><a class="linkGaleria" @click="goTop()">Topo</a></span>
          </div>
        </div>
      </div>
    </div>
  </v-content>
</template>

<script>
import AppBar from '../components/AppBar.vue'
export default {
  name: 'EdicaoMaiJun',
  components: {
    AppBar
  },
  data () {
    return {
      panel: [0],
      scTimer: 0,
      scY: 0,
    }
  },
  methods: {
    gotoExt () {
      window.open(
        this.url,
        '_blank'
      );
    },
    gotoInt () {
      window.location.href = this.dirr
    },
    gotoPage (link) {
      var target = link === this.url ? '_blank' : '_self'
      window.open(
        link,
        target
      );
    },
    voltar () {
      this.$router.back()
    },
    onScroll () {
      this.drawer ? this.drawer = false : this.drawer = true
    },
    goTop () {
      this.panel = []
    }
  },
}
</script>
<style scoped>
    @font-face {
      font-family: Letra;
      src: url('../assets/fonts/OpenSans-VariableFont_wdth,wght.ttf');
    }
    @font-face {
      font-family: Laube;
      src: url('../assets/fonts/Blue Vinyl Fonts - BlueVinyl-Bold.otf');
    }
    .laubeFont {
      font-family: Letra !important;
      font-weight: bolder !important;
    }
    .imgweb {
      background-image: url('../assets/img/fundo2web.png');
      background-color: #fdede6;
      background-size: cover;
      background-position-y: -100px;
      background-attachment: fixed;
      min-height: 100vh;
      height: 100%;
      width: 100%;
      background-repeat: no-repeat;
    }
    .imgmobi {
      background-image: url('../assets/img/fundocel2.png');
      background-color: #d4c1ba;
      background-position: top;
      background-size: cover;
      height: 100vh;
      background-repeat: no-repeat;
    }
    .linkGaleria {
      display: flex;
      flex-direction:row-reverse;
      text-decoration: none;
      color:#70445E;
    }
    .imgcenter {
      display: flex;
      flex-direction: row;
      align-items: center !important;
      justify-content: center !important;
    }
</style>
